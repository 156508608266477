<template src="./performed.html"></template>

<script>
import mixins from "../../../../mixins/mixins.js";
export default {
  mixins: [mixins],
  data: () => ({
    windowWidth: 0,
    toRerenderWebTable: 0,
    toRerenderMobileTable: 0,
    showWebTable: true,
    performed: [],
    performedPaginated: [],
    selected: [],
    currentx: 1,
    pagination: false,
    numPages: 0,
    searchedWord: "",
    ratingAppointment: null,
    reasonComment: null,
    starSize: 30,
    name: "",
    date: "",
    professional: "",
    clinic: "",
    canEdit: false,
    idMedicalRecord: null,
    token: null,
    disabledButtonSendRating: false,
    characterlimit: 255,
    showWebButton: true,
    busy: false,
    timeout: null,
    returnStatus: null,
  }),
  created: function () {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  destroyed: function () {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  mounted: function () {
    const vm = this;
    vm.init();
  },
  watch: {
    performed: function(newVal){
      const vm = this;
      vm.performed = newVal;
    },
    ratingAppointment: function () {
      const vm = this;
      vm.disabledButtonSendRating = vm.testNullUndefinedEmpty(vm.reasonComment);
    },
    reasonComment: function (newVal) {
      const vm = this;
      const limit = 255;
      vm.characterlimit = limit;
      if (
        vm.ratingAppointment <= 5 &&
        vm.testNullUndefinedEmpty(newVal) === false
      ) {
        if (newVal.trim().length > 0) {
          vm.disabledButtonSendRating = false;
          vm.characterlimit = limit - vm.reasonComment.length;
        } else {
          vm.disabledButtonSendRating = true;
        }

        if (vm.characterlimit < 0) {
          vm.characterlimit = 0;
        }
      } else if (vm.ratingAppointment <= 5 && vm.testNullUndefinedEmpty(newVal)) {
        vm.disabledButtonSendRating = true;
      }
      if(vm.testNullUndefinedEmpty(newVal) === false){
        if(newVal.length > limit){
          vm.reasonComment = newVal.slice(0, -1);
        }
      }
    },
    currentx: function () {
      const vm = this;
      vm.handleChangePage();
    },
  },
  methods: {
    init() {
      const vm = this;
      vm.getPerformed();
    },
    handleResize() {
      const vm = this;
      vm.windowWidth = window.innerWidth;
      if (vm.windowWidth <= 1200) {
        vm.showWebTable = false;
        if(vm.windowWidth <= 939){
          vm.showWebButton = false;
        } else {
          vm.showWebButton = true;
        }
      } else {
        vm.showWebTable = true;
      }
    },
    handleSearch(searching) {
      const vm = this;
      vm.searchedWord = searching;
      vm.currentx = 1;
      setTimeout(() => {
        vm.init();
      }, 1000);
    },
    handleChangePage() {
      const vm = this;
      vm.getPerformed();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getPerformed() {
      const vm = this;
      vm.$vs.loading();
      let page = 0;
      if (vm.currentx > 0) {
        if (vm.currentx == 1) {
          page = 0;
        } else {
          page = vm.currentx - 1;
        }
      }
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "appointments/history?page=" +
            page +
            "&search=" +
            vm.searchedWord +
            "&size=10&sort=id,desc",
          {
            headers: {
              Authorization: vm.$session.get("bearer"),
            },
          }
        )
        .then(function (response) {
          if(response.status === 200){
            vm.performed = response.data.content;
            vm.numPages = response.data.totalPages;
            vm.pagination = vm.numPages > 1;
            vm.$vs.loading.close();
          }

        }).catch(function () {
          vm.$vs.loading.close();
        });
    },
    viewMedicalRecord(medRecId, patientId){
      const vm = this;
      vm.$router.push({
        path: "/patient-medical-record",
        name: "patient-medical-record",
        params: {
          medRecId: medRecId,
          justSeeNew: false,
          justSeePerformed: true,
          typeOfMedicalCare: "performed",
          patientId: patientId,
        },
      });
    },
    updateList(){
      const vm = this;
      vm.searchedWord = "";
      vm.currentx = 1;
      if (vm.toRerenderWebTable === 0) vm.toRerenderWebTable += 1;
      else vm.toRerenderWebTable -= 1;
      if (vm.toRerenderMobileTable === 0) vm.toRerenderMobileTable += 1;
      else vm.toRerenderMobileTable -= 1;
      vm.init();
    },
    clearTimeout() {
      const vm = this;
      if (vm.timeout) {
        clearTimeout(vm.timeout);
        vm.timeout = null;
      }
    },
    setTimeout(callback) {
      const vm = this;
      vm.clearTimeout();
      vm.timeout = setTimeout(() => {
        vm.clearTimeout();
        callback();
      }, 2000);
    },
    getDay(dateHour) {
      return dateHour.substring(1, 10);
    },
    getHour(dateHour) {
      return dateHour.substring(12, 16);
    },
    getDayOfWeek(date) {
      date = date.substring(1, 10);
      var dayOfWeek = new Date(date).getDay();
      return isNaN(dayOfWeek)
        ? null
        : [
          "Domingo",
          "Segunda-feira",
          "Terça-feira",
          "Quarta-feira",
          "Quinta-feira",
          "Sexta-feira",
          "Sábado",
        ][dayOfWeek];
    },
    clearConsole(){
      setTimeout(() => {
        console.clear();
      }, 150);
    },
    clickBtnScheduleReturn(selected) {
      const vm = this;
      vm.startReturnSchedule(selected.medicalRecordId, selected.clinic.id);
    },
    startReturnSchedule(medRecId, clinicId) {
      const vm = this;
      vm.busy = true;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "schedules/post-return-schedule/" +
            medRecId +
            "/" +
            clinicId,
          {
            headers: {
              Authorization: vm.$session.get("bearer"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 201 || response.status === 200) {
            vm.setTimeout(() => {
              vm.busy = false;
              vm.$bvModal.hide("modal-status-return");
            });
            setTimeout(() => {
              vm.$bvModal.show("modal-return-schedule-success");
            }, 2005);

          }
        })
        .catch(function (error) {
          vm.setTimeout(() => {
            vm.busy = false
          });
          const errorMessage = error.data.message;
          if (errorMessage.search("FALTA SALDO") >= 0) vm.$bvModal.show("modal-return-schedule-fail-balance");
          else vm.$bvModal.show("modal-return-schedule-fail");
        });
    },
    ratingMedicalRecord(selected) {
      const vm = this;
      vm.selected = selected;
      vm.getInfoMedicalRecord();
    },
    scheduleReturn(selected) {
      const vm = this;
      vm.selected = selected;

      vm.axios
        .get(
          vm.$store.state.filooServer +
            "schedules/return-status/" +
            vm.selected.medicalRecordId,
          {
            headers: {
              Authorization: vm.$session.get("bearer"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 201 || response.status === 200) {
            vm.returnStatus = response.data;
            vm.$bvModal.show("modal-status-return");
          }
        })
        .catch(function () {
          vm.showNotifyError("Não foi possível buscar as informações do retorno, tente novamente!");
        });
    },
    getInfoMedicalRecord() {
      const vm = this;
      vm.idMedicalRecord = vm.selected.medicalRecordId;
      vm.token = vm.selected.rateToken;
      vm.name = "";
      vm.date = "";
      vm.professional = "";
      vm.clinic = "";
      vm.canEdit = false;
      vm.ratingAppointment = null;
      vm.reasonComment = "";
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.idMedicalRecord +
            "/quick-access?token=" +
            vm.token
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.name = response.data.nomePaciente;
            vm.date = response.data.date;
            vm.professional = response.data.nomeMedico;
            vm.clinic = response.data.nomeConsultorio;
            vm.canEdit = response.data.canEdit;
            if (response.data.comentario === null) {
              vm.reasonComment = null;
            } else {
              const comment = response.data.comentario;
              if (comment.trim().length == 0) {
                vm.reasonComment = null;
              } else {
                vm.reasonComment = response.data.comentario;
              }
            }
            vm.ratingAppointment = response.data.estrelas;
            vm.$bvModal.show("modal-lg-rating");
          }
        })
        .catch(function () {
          vm.showNotifyError("Não foi possível obter os dados da consulta selecionada");
        });
    },
    validateFields(){
      const vm = this;
      let valid = true;
      let time = null;
      let msg = null;
      if(vm.testNullUndefinedEmpty(vm.ratingAppointment)){
        valid = false;
        time = 3500;
        msg ="Selecione uma nota antes de confirmar a avaliação.";
      }
      if(vm.ratingAppointment <= 2){
        if(vm.testNullUndefinedEmpty(vm.reasonComment)){
          valid = false;
          time = 9000;
          msg = "Sua opinião é muito importante para nós. Ajude-nos em nosso processo de evolução contínua e explique o motivo da sua avaliação negativa.";
        }
      }
      if(valid === true){
        vm.sendRating();
      } else {
        vm.showNotifyWarning(msg, time);
      }
    },
    sendRating() {
      const vm = this;
      const rating = {
        estrelas: vm.ratingAppointment,
        comentario: vm.ratingAppointment <= 5 ? vm.reasonComment : null,
      };
      vm.axios
        .put(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.idMedicalRecord +
            "/quick-access?token=" +
            vm.token,
          rating
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.$bvModal.hide("modal-lg-rating");
            vm.$bvModal.show("modal-lg-rating-success");
          }
        })
        .catch(function () {
          vm.$bvModal.hide("modal-lg-rating");
          vm.$bvModal.hide("modal-lg-rating-success");
          vm.showNotifyError("Não foi possível avaliar a consulta selecionada");
        });
    },
    goToRecharge() {
      var vm = this;
      vm.$router.push({ name: "wallet", params: {} });
      vm.$bvModal.hide("modal-return-schedule-fail-balance");
    },
  },
};
</script>

<style lang="scss" src="./performed.scss">
</style>
